<template>
	<div id="webFooter">
		<!-- 页脚 -->
		<div class="footerCont">
			<div class="cont">
				<div class="list">
					<ul>
						<li class="tit">项目介绍</li>
						<li>项目介绍</li>
						<li>参与流程 </li>
						<li>投稿要求</li>
					</ul>
					<ul>
						<li class="tit">投稿专区</li>
						<li>项目概况</li>
						<li>优秀作品</li>
						<li>入围作品</li>
					</ul>
					<!-- <ul>
						<li class="tit">新闻中心</li>
					</ul> -->
					<ul>
						<li class="tit">联系我们</li>
						<li>联系方式</li>
						<li>意见反馈</li>
						<li>联系我们</li>
					</ul>
				</div>
				<!-- <p class="Copyright">
					COPYRIGHT © 2014-2022, 生命绿洲有限公司 版权所有 工信部备案号：沪ICP备2023007777号
				</p> -->
			</div>

			<el-image :src="require(`@/assets/imgs/qrcode.png`)" fit="cover"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'webFooter',
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>

<style scoped lang="scss">
	#webFooter {
		padding: 100px 0 50px;

		.footerCont {
			width: 1200px;
			display: flex;
			flex-direction: row;
			margin: auto;
			align-items: center;
			justify-content: space-between;
			flex: 1;

			.cont {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				// margin-bottom: 60px;
				width: 100%;

				.list {
					display: flex;
					flex-direction: row;
					justify-content: space-around;

					ul {
						flex: 1;

						.tit {
							font-size: 20px;
							color: rgba(51, 51, 51, 1);
							margin-bottom: 40px;
						}

						li {
							font-size: 16px;
							color: rgba(153, 153, 153, 1);
							margin-bottom: 20px;
						}
					}
				}

			}

			.Copyright {
				text-align: center;
				line-height: 2;
				color: rgba(61, 61, 61, 1);
				margin-top: 40px;
			}
		}
	}
</style>