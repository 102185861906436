<template>
	<div id="app">
		<webHander />
		<transition name="slide-left">
			<router-view class="appView" />
		</transition>
		<webFooter />
		<!-- <el-image class="fixed" :src="require('@/assets/imgs/qutougao.png')" @click="toContribute"></el-image> -->
		<div class="fixed" v-if="$route.name!='UserCenter'">
			<div class="top">
				<p>便捷入口</p>
				<!-- <b>快速参与项目，点下方按钮</b> -->
				<img src="./assets/imgs/man.png">
			</div>

			<div class="btm">
				<div class="zhuce" @click="zhuce">
					<img src="./assets/imgs/grzc.svg">
					<div class="text">
						<p>个人注册</p>
						<!-- <b>点击按表单填写个人信息，完成项目个人注册</b> -->
					</div>
				</div>
				<div class="tougao" @click="tougao">
					<img src="./assets/imgs/cytg.svg">
					<div class="text">
						<p>立即投稿</p>
						<!-- <b>已注册用户，可通过此处快速提交稿件</b> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import webHander from './components/webHander'
	import webFooter from './components/webFooter'
	export default {
		name: 'Home',
		components: {
			webHander,
			webFooter
		},
		data() {
			return {}
		},
		watch: {
			//使用watch 监听$router的变化
			$route(to, from) {
				//如果to索引大于from索引,判断为前进状态,反之则为后退状态
				if (to.meta.index > from.meta.index) {
					//设置动画名称
					this.transitionName = "slide-left";
				} else {
					this.transitionName = "slide-right";
				}
			},
		},

		created() {},
		mounted() {},
		methods: {
			zhuce() {
				this.$router.push({
					path: "/UserCenter",
					query: {
						opt: "register"
					}
				})
			},

			tougao() {
				this.$router.push({
					path: "/UserCenter",
					query: {
						opt: 'contribute'
					}
				})
			},

			toContribute() {
				this.$router.push({
					path: "/UserCenter"
				})
			}
		}
	}
</script>
<style lang="scss">
	@import url('./assets/css/reset.css');

	#app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		min-width: 1400px;

		.appView {
			flex: 1;
			width: 100%;
			height: auto;
			// position: absolute;
			// top: 0;
			// bottom: 0;
			// margin: 0 auto;
			// -webkit-overflow-scrolling: touch;

		}

		// .slide-right-enter-active,
		// .slide-right-leave-active,
		// .slide-left-enter-active,
		// .slide-left-leave-active {
		// 	height: 100%;
		// 	will-change: transform;
		// 	transition: all 300ms;
		// 	position: absolute;
		// 	backface-visibility: hidden;
		// }

		// .slide-right-enter {
		// 	opacity: 0;
		// 	transform: translate3d(-100%, 0, 0);
		// }

		// .slide-right-leave-active {
		// 	opacity: 0;
		// 	transform: translate3d(100%, 0, 0);
		// }

		// .slide-left-enter {
		// 	opacity: 0;
		// 	transform: translate3d(100%, 0, 0);
		// }

		// .slide-left-leave-active {
		// 	opacity: 0;
		// 	transform: translate3d(-100%, 0, 0);
		// }

		// .van-badge--fixed {
		// 	z-index: 1000;
		// }


		.fixed {
			position: fixed;
			right: 30px;
			bottom: 30px;
			width: 200px;
			display: flex;
			flex-direction: column;
			box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
			z-index: 10;

			.top {
				background: #ECF4FF;
				display: flex;
				flex-direction: column;
				padding: 15px 20px;
				border-radius: 10px 10px 0 0;
				position: relative;

				img {
					position: absolute;
					right: 0;
					bottom: 0;
					height: 150%;
				}

				p {
					font-size: 16px;
					color: rgba(51, 136, 255, 1);

				}

				b {
					font-size: 14px;
					color: rgba(19, 76, 155, 1);
					font-weight: normal;
				}
			}

			.btm {
				background-color: #fff;
				border-radius: 0 0 10px 10px;
				padding: 10px;
				display: flex;
				flex-direction: column;

				>div {
					display: flex;
					flex-direction: row;
					background: rgba(249, 249, 249, 1);
					padding: 10px;
					margin-bottom: 10px;
					border-radius: 10px;

					* {
						cursor: pointer;
					}

					&:last-child {
						margin-bottom: 0;
					}

					img {
						width: 30px;
						height: 30px;
						margin-right: 10px;
					}

					.text {
						display: flex;
						align-items: center;

						p {
							font-size: 16px;
							color: #000;
						}

						b {
							font-size: 14px;
							color: rgba(153, 153, 153, 1);
							font-weight: normal;
						}
					}
				}
			}
		}
	}
</style>